export default {
  t_welcome: "Welcome", //Login
  t_copyright: "Copyright ©️ 2023 ver3.1.0",
  t_userLogin: "User Login",
  t_userName: "User Name",
  t_password: "Password",
  t_login: "Login",
  t_hello: "Hello",
  t_usernameOrPasswordIsInvalid: "Username or password is invalid.",
  t_successfullyLoggedIn: "Successfully Logged In.",
  t_pleaseSelectQueueAndClassification: "Please select queue and classification",
  t_PleaseSelectProjetAndQueues: "Please select project and queues",
  t_pleaseSingIn: "Please sign in",
  t_rememberMe: 'Remember me',
  t_signIn: 'Sign In',
  t_changeStatusAfterTheCall: "Change Status After The Call", //LoyoutContentRedererDefault.vue //
  t_remainingTime: "Remaining Time",
  t_rCallTimeAndCounts: "CallTime And Counts",
  t_collections: "Collections",
  t_rClassificationDetails: "Classification Details",
  t_rdialerconnectedtoagent: "Dialer Connected To Agent",
  t_hourly: "Hourly",
  t_rAcwLength: "AcwLength",
  t_rdialerabandon: "Dialer Abandon",
  t_continue: "Continue",
  t_hold: "Hold",
  t_rVerdictDetails: "Verdict Details",
  t_autoRecycle: "Auto Recycle",
  t_abandonedCampaign: "Abandoned Campaign",
  t_goalListByAgents: "Goal List By Agents",
  t_microphoneOn: "Microphone On",
  t_microphoneOff: "Microphone Off",
  t_inputList: "Dial List",
  t_showInputs: "Show Inputs",
  t_chatPanel: "Chat Panel",
  t_showDetail: "Show Detail",
  t_connected: "Connected", // Navbar.vue
  t_disconnected: "Disconnected",
  t_dayMode: "Day Mode",
  t_nightMode: "Night Mode",
  t_agentList: "Agent List",
  t_logOut: "Log Out", // AgentTargets //
  t_collectionTargeting: "Collection Targeting",
  t_targeting: "Targeting",
  t_completed: "Completed",
  t_isCompleted: "Completed",
  t_dashboard: "Dashboard",
  t_search: "Search",
  t_add: "Add",
  t_targetedMonth: "Targeted Month",
  t_confirm: "Confirm",
  t_areYouSureYouWantToDeleteTheSelectedRecord: "Are you sure you want to delete the selected record?",
  t_areYouSureYouWantToUpdateTheSelectedRecords: "Are you sure you want to update the selected records?",
  t_areYouSureYouWantToClearAll: "Are you sure you want to clear all?",
  t_selectTheAgent: "Select the agent...",
  t_collectedDate: "Collected Date",
  t_collectedTime: "Collected Time",
  t_edit: "Edit",
  t_remaining: "Remaining",
  t_compilationRate: "Compilation Rate",
  t_minute: "Minute",
  t_hour: "Hour",
  t_day: "Day",
  t_week: "Week",
  t_month: "Month",
  t_year: "Year",
  t_weekday: "Weekday",
  t_lastOneHour: "Last 1 Hour",
  t_lastThreeHours: "Last 3 Hour",
  t_twoDaysAgo: "2 Days Ago",
  t_threeDaysAgo: "3 Days Ago",
  t_fourDaysAgo: "4 Days Ago",
  t_fiveDaysAgo: "5 Days Ago",
  t_sixDaysAgo: "6 Days Ago",
  t_lastWeek: "Last Week",
  t_get: "Get",
  t_target: "Target",
  t_announcements: "Announcements", // Announcements //
  t_announcementName: "Announcement Name",
  t_selectAudioFile: "Select audio file...",
  t_announcementList: "Announcement List",
  t_areYouSure: "Are You Sure?",
  t_ok: "Ok",
  t_count: "Count",
  t_CompletedAmount: "Collected Amount",
  t_dailyTarget: "Daily Target",
  t_monthlyTarget: "Monthly Target",
  t_dailyTimeline: "Daily time line",
  t_blackList: "Blacklist", // BlackListManagement //
  t_descriptions: "Descriptions",
  t_inbound: "Inbound",
  t_outbound: "Outbound",
  t_number: "Number",
  t_callHistory: "Call History", // CallHistory //
  t_numberHistory: "Number History",
  t_notes: "Notes",
  t_oldNotes: "Old Notes",
  t_campaigns: "Campaigns", // Campaigns //
  t_campaign: "Campaign",
  t_campaignName: "Campaign Name",
  t_campaignList: "Campaign List",
  t_campaignhasBeenDeletedSuccessfully: "Campaign has been deleted successfully.",
  t_campaignhasBeenUpdatedSuccessfully: "Campaign has been updated successfully.",
  t_campaignhasBeenCreatedSuccessfully: "Campaign has been created successfully.",
  t_customTabs: "Custom Tabs", // CustomTabs //
  t_address: "Address",
  t_landlinePrefix: "Land Lİne Prefix",
  t_fctPrefix: "FCT Prefix",
  t_customTabList: "Custom Tab List",
  t_dataManagement: "Data Management", // DataUpload //
  t_productManagement: "Product Management",
  t_scriptManagement: "Script Management",
  t_exampleFile: "Example File",
  t_uploadFile: "Upload File",
  t_SelectTheTitlesToUpdate: "Select the titles to upload...",
  t_setUnmatchedRecordsToPassive: "Set unmatched records to pasive?",
  t_setUnmatchedRecordsToUpdate: "Set unmatched records to update?",
  t_setAllPreviousInstitutionDataToPassive: "Set all previous institution data to passive?",
  t_updateMatchedRecords: "Update matched records?",
  t_setAllRecordsOfProjectNameToPassive: "Set all records of project name to passive?",
  t_upload: "Upload",
  t_0customersHasBeenUpdatedSuccessfully: "0 Customers has been updated successfully.",
  t_customersHasBeenUpdatedSuccessfully: "Customers has been updated successfully.",
  t_customersCouldNotBeUploadedBecauseThereHasBeenAnError: "Customers could not be uploaded because there has been an error.",
  t_pleaseSelectAProject: "Please select a project...",
  t_dialPlan: "Dial Plan", // DialPlan //
  t_classifications: "Classifications",
  t_simulation: "Simulation",
  t_check: "Check",
  t_order: "Order", //DIAL PLAN PRIORITY1
  t_callingPatterns: "Calling Patterns",
  t_dialPattern: "Dial Pattern",
  t_simulatedCallCouldBePlacedFrom: "Simulated call could me placed from: ",
  t_couldNotPlaceSimulationCall: "Could not place simulation call!",
  t_totalAgents: "Total Agent", // Live Dashboard //
  t_conference: "Conference",
  t_available: "Available",
  t_callsInQueue: "Calls In Queue",
  t_abandonedCount: "Abandoned Count",
  t_hourlyAbandonedCount: "Hourly Abandoned Count",
  t_endTime: "End Time",
  t_statusLength: "Status Length",
  t_massChangeStatuses: "Mass Change Statuses",
  t_textColour: "Text Colour",
  t_audioFile: "Audio File",
  t_type: "Type",
  t_confetti: "Confetti",
  t_champagne: "Champagne",
  t_emergency: "Emergency",
  t_blink: "Blink",
  t_wallboardControl: "Wallboard Control",
  t_writtenSouffle: "Written Souffle",
  t_listen: "Listen",
  t_clap1: "Clap1",
  t_clap2: "Clap2",
  t_warning: "Warning",
  t_yourMessage: "Your message...", // Home.vue
  t_changeAllStatuses: "Change All Statuses",// Home.vue
  t_showWentHome: "Show Went Home", // Home.vue
  t_hideWentHome: "Hide Went Home", // Home.vue
  t_showCalledBack: "Show Called Back",// Home.vue
  t_reakTimeFraud: "Reak Time Fraud", // Home.vue
  t_finishCodeCategory: "Finish Code Category", // FinishCodeManagement //
  t_categoryName: "Category Name",
  t_finishCodeCategorys: "Finish Code Categorys",
  t_finishCodeName: "Finish Code Name",
  t_finishCodes: "Finish Code",
  t_callFinishCode: "Call Finish Code",
  t_writtenChannelFinishCode: "Test Channel Finish Code",
  t_productFinishCode: "Product Finish Code",
  t_askForActionDate: "Ask For Action Date?",
  t_saveToCalendar: "Save To Calendar?",
  t_contactCallableAfterwards: "Contact Callable Afterwards?",
  t_contactNumberCallableAfterwards: "Number Callable Afterwards?",
  t_countAsCallAttempt: "Count As Call Attempt?",
  t_lastFinishCode: "Last Finish Code?",
  t_deleteContactNumber: "Delete Contact Number?",
  t_assignToAgent: "Assign To Agent?",
  t_inboundRoutes: "Inbound Routes", // InboundRoutes //
  t_standardRoute: "Standart Route",
  t_lineGroup: "Line Group", // LineGroup //
  t_lineGroupName: "Line Group Name",
  t_lineGroups: "Line Groups",
  t_addedLines: "Added Lines",
  t_lineList: "Line List", // Lines //
  t_importLines: "Import Lines",
  t_lineName: "Line Name",
  T_RTRANSFERTYPE: "Transfer Type",
  T_RTRANSFERTARGET: "Transfer Target",
  t_outboundCID: "Outbound CID",
  t_transferCID: "Transfer CID",
  t_hide_ofline_users: "Hide Ofline Users",
  t_ip: "IP",
  t_transportType: "Transport Type",
  t_type: "Type",
  t_prefix: "Prefix", // LinesLines > REGISTER PW
  t_inboundCallLimit: "Inbound Call Limit",
  t_outboundCallLimit: "Outbound Call Limit",
  t_port: "Port",
  t_codec: "Codec",
  t_fileUpload: "File Upload",
  t_file: "File",
  t_selectTheFileYouWantToUpload: "Select the file you want to upload...",
  t_pleaseEnterTheLineGroupName: "Please enter the line group name.",
  t_youNeedToPickALineToBeAbleToCreateLineGroup: "You need to pick a line to be able to create line group.",
  t_lineGroupHasBeenCreatedSuccessfully: "Line Group has been created successfully.",
  t_campaignManagement: "Campaign Management", // IvnDialer //
  t_campaignControl: "Campaign Control",
  t_stop: "Stop",
  t_recycle: "Recycle",
  t_calls: "Calls",
  t_basic: "Basic",
  t_scheduledCalls: "Scheduled Calls",
  t_contactList: "Contact List",
  t_programming: "Programming",
  t_priority: "Priority",
  t_maxCall: "Max Call",
  t_callsPerAgent: "Calls Per Agent",
  t_statisticsPeriod: "Statistics Period (min)",
  t_callingMethod: "Calling Method",
  t_noAnswerTimeout: "No Answer Timeout (sec)",
  t_maxAbandonRate: "Max Abandon Rate (%)",
  t_dontConnectMachines: "Don't Connect Machines",
  t_sortCallList: "Sort Call List",
  t_maxCycles: "Max Cycles",
  t_recallDelayMins: "Recall Delay Mins (min)",
  t_afterCall: "After Call",
  t_afterCallStatus: "After Call Status",
  t_maxAttempts: "Max Attempts",
  t_maximumRateOfCallsThatAreAbandonedFromTheQueue: "Maximum rate of calls that are abandoned from the queue",
  t_maximumAmountOfCallsThisCampaignCanStart: "Maximum amount of calls this campaign can start",
  t_callsPerAvailableAgents: "Calls per available agents",
  t_refreshRateOfThisCampaignsStatisticsGraphs: "Refresh rate of this campaings statistics graphs",
  t_whichLineGroupToMakeCallsFrom: "Which line group to make calls from",
  t_maximumRingingTime: "Maximum ringing time",
  t_amountOfRoundsToCallPeopleWhoHaventBeenContactedYet: "Amount of rounds to call people who haven't been contacted yet",
  t_minimumAmountOfMinutesToRecallAPhoneNumber: "Minimum amount of minutes to recall a phone number",
  t_maximumCallAmountPerPhoneNumberPerDay: "Maximum call amount per phone number per day",
  t_maximumCallAmountPerContactPerDay: "Maximum call amount per contact per day",
  t_maximumCallAmountPerPhoneNumber: "Maximum call amount per phone number",
  t_maximumCallAmountPerContact: "Maximum call amount per contact",
  t_doNotConnectMachineAnsweredCalls: "Do not connect machine answered calls",
  t_dailyMaxPhoneTouch: "Daily Max Phone Touch",
  t_dailyMaxContactTouch: "Daily Max Contact Touch",
  t_totalMaxPhoneTouch: "Total Max Phone Touch",
  t_totalMaxContactTouch: "Total Max Contact Touch",
  t_justCallMobilePhone: "Just Call Mobile Phone",
  t_abandoned: "Abandoned",
  t_timeCondition: "Time Condition",
  t_detail: "Detail",
  t_transferIVR: "Transfer IVR",
  t_liveContacttIVR: "Live Contact IVR",
  t_liveContact: "Live Contact",
  t_noAvailableAgent: "No Available Agent", // Dialer.vue //
  t_noAvailableAgentIVR: "No Available Agent IVR",
  t_rule: "Rule",
  t_campaignStatus: "Status",
  t_campaignPriority: "Priority",
  t_campaignFilterCriteria: "Filter Criteria",
  t_campaignSortingCriteria: "Sorting Criteria",
  t_campaignRemainingCycles: "Remaining Cycles",
  t_campaignCallPerAgent: "Call PerAgent",
  t_campaignTotalPhonesToCall: "Total Phones To Call",
  t_campaignRemainingPhonesInCycle: "Remaining Phones In Cycle",
  t_campaignTotalCalled: "Total Called",
  t_campaignTotalPhonesCalled: "Total Phones Called",
  t_campaignConnectedCall: "Connected Call",
  t_campaignWaitingInQueue: "Waiting In Queue",
  t_campaignAbandonRate: "Abandon Rate %",
  t_resultNoAnswer: "No Answer",
  t_resultAbandon: "Abandon",
  t_resultBusy: "Busy",
  t_resultCallRejected: "Call Rejected",
  t_resultFaultyCall: "Faulty Call",
  t_resultNetworkOutOfOrder: "Network Out Of Order",
  t_resultFateUnknown: "Fate Unknown",
  t_resultConnectivityError: "Connectivity Error",
  t_resultFaultyNumber: "Faulty Number",
  t_finishCodeStatistics: "Finish Code Statistics",
  t_customersStatus: "Customer Status",
  t_assigned_agent: "Assigned Agent",
  t_attempts: "Attempts",
  t_dial_plan: "Dial Plan",
  t_finish_code: "Finish Code",
  t_insert_date: "Insert D.",
  t_action_date: "Action D.",
  t_create_date: "Create D.",
  t_ex_agent: "Ex Agent",
  t_assign_date: "Assign D.",
  t_pleaseMakeASelectionForSorting: "Please make selections for sorting.",
  t_started: "Started",
  t_stopped: "Stopped",
  t_recycling: "Recycling",
  t_settingsHasBeenSavedSuccessfully: "Settings has been saved success fully.",
  t_columns: "Columns", //Main.vue
  t_row: "Row",
  t_sort: "Sort", //Main.vue
  t_algorithmicDialer: "Algorithmic Dialer", // List.vue
  t_basicDialer: "Basic Dialer", // List.vue
  t_filterManagement: "Filter Management", // IvnDialerFilter //
  t_noFiltersForThisCampaign: "No Filters For This Campaign.", // IvnDialerFilter > t_fiterManagemt > t_value
  t_condition: "Condition",
  t_equal: "Equal",
  t_notEqual: "Not Equal",
  t_lessThan: "Less Than",
  t_greaterThan: "Greater Than",
  t_lessThanOrEqual: "Less Than Or Equal",
  t_greaterThanOrEqual: "Greater Than Or Equal",
  t_columnName: "Name",
  t_columnNameStatus: "Name Status",
  t_columnNameAssignedAgent: "Assigned Agent",
  t_columnNameAttempts: "Attempts",
  t_columnNameCampaign: "Campaign",
  t_columnNameFinishCode: "Finish Code Name",
  t_columnNameInsertDate: "Insert D.",
  t_columnNameActionDate: "Action D.",
  t_columnNameCreateDate: "Create D.",
  t_columnNameExAgent: "Ex Agent",
  t_filterHasBeenCreatedSuccessfully: "Filter has been created successfully.",
  t_IVR: "IVR", // IvrManagement //
  t_IVRName: "IVR Name",
  t_IVRList: "IVR List",
  t_DTMFdigit: "DTMF Digit",
  t_announcementFile: "Announcement File",
  t_DTMFAccept: "DTMF Accept",
  t_extension: "Extension",
  t_transfer: "Transfer",
  t_language: "Language",
  t_timeOutLengthSec: "Time Out Length (Sec)",
  t_timeOutCount: "Time out Count",
  t_maxInvalidInputCount: "Max Invalid Input Count",
  t_acceptDTMFExtension: "Accept DTMF Extension",
  t_turkish: "Turkish",
  t_english: "English",
  t_arabic: "Arabic",
  t_russian: "Russian",
  t_hangup: "Hangup",
  t_hangupCall: "Hangup Call",
  t_incomingCall: "Incoming Call",
  t_answer: "Answer",
  t_menu: "Menu",
  t_matched: "Matched:",
  t_unmatched: "Unmatched:",
  t_hasBeenDeletedSuccessfully: "Has been deleted successfully.",
  t_pleaseDoNotLeaveAnyBlankFields: "Please do not leave any black fields.",
  t_hasBeenUpdatedSuccessfully: "Has been updated successfuly.",
  t_hasBeenCreatedSuccessfully: "Has been created successfuly.",
  t_pleaseSelectTheBlackListDirection: "Please select the black list direction.", // blacklist
  t_phoneBook: "Phone Book", // PhoneBook //
  t_addPhone: "Add Phone",
  t_description: "Description",
  t_profiles: "Profiles", // ProfileQueueManagement //
  t_profileName: "Profile Name",
  t_affectedObjects: "Affected Objects",
  t_permissions: "Permissions",
  t_ivr: "Ivr",
  t_phone_book: "Phone Book",
  t_user: "User",
  t_reset: "Reseat",
  t_noPermissionsSelected: "No permissions selected.",
  t_profileList: "Profile List",
  t_chat: "Chat",
  t_souffle: "Souffle",
  t_profileHasBeenDeletedSuccessfully: "Profile has been deleted successfully.",
  t_profileHasBeenUpdatedSuccessfully: "Profile has been updated successfully.",
  t_profileHasBeenCreatedSuccessfully: "Profile has been created successfully.",
  t_is_visible_in_live_dasboard: "Is Visible In Live Dasboard?",
  t_projectName: "Project Name", // Projects //
  t_afterCallWork: "After Calll Work (sec)",
  t_cameraRecord: "Camera Record?",
  t_useableBreakTime: "Usable Break Time (min)",
  t_breakTime: "Break Time",
  t_surname: "Surname",
  t_nameSurname: "Name Surname",
  t_projectList: "Project List",
  t_projectHasBeenDeletedSuccessfully: "Project has been deleted successfully.",
  t_projectHasBeenUpdatedSuccessfully: "Project has been updated successfully.",
  t_projectHasBeenCreatedSuccessfully: "Project has been created successfully.",
  t_queueNumber: "Number", // Queues - List.vue //
  t_queueAgentTimeout: "Agent Timeout (sec)",
  t_queueAgentTimeoutPlaceholder: "Agent Timeout Placeholder",
  t_queueAnnounceFrequency: "Announce Frequency (sec)",
  t_timeBetweenAnnouncements: "Time between announcements",
  t_queueAnnouncePosition: "Announce Position",
  t_queueMaxlen: "Max Len",
  t_queuePenaltyMembersLimit: "Penalty Members Limit",
  t_queueRetry: "Retry",
  t_queueMusicOnHold: "Music On Hold",
  t_queueName: "Queue Name",
  t_queueCallingMode: "Calling Mode",
  t_queueStrategy: "Queue Strategy",
  t_queueStrategyPlaceholder: "Queue Strategy Placeholder",
  t_queueWrapupTime: "Wrapup Time (sec)",
  t_queueWrapupTimePlaceholder: "Queu Wrapup Time Placeholder",
  t_queueAnnounceHoldTime: "Announce Hold Time",
  t_queueQueueHoldTime: "Queue Auto Hold Time", //! bak
  t_queueMemberDelay: "Member Delay",
  t_queuePeriodicAnnounceFrequency: "Periodic Announce Frequency",
  t_queueServiceLevel: "Service Level",
  t_queueWeight: "Weight",
  t_queueList: "Queue List",
  t_queueRrmemory: "Rrmemory (Recommended)",
  t_queueRingall: "Ringall",
  t_queueLeastrecent: "Leastrecent",
  t_queueAgentWeight: "Agent Weight",
  t_queueFewestcalls: "Fewestcalls",
  t_queueRandom: "Random",
  t_queueManual: "Manual",
  t_queueDialer: "Dialer",
  t_serviceManagement: "Service Management", // ServiceManagement //
  t_dialerService: "Dialer Service",
  t_restart: "Restart",
  t_callManager: "Call Manager",
  t_chatService: "Chat Service",
  t_smsSending: "Send SMS",
  t_smsTemplate: "Template",
  t_smsFilter: "Filter",
  t_filterName: "Filter Name", // SmsFilter //
  t_noFilterHasBeenSelected: "No filter has been selected.",
  t_filterList: "Filter List",
  t_smsPageTitle: "Page Title", // SmsSender //
  t_sendFromVoyceCRM: "Send From VoyceCRM",
  t_sendFromExternalCRM: "Send From ExternalCRM",
  t_sendFromCustomSms: "Send From Custom SMS",
  t_smsPattern: "SMS Pattern",
  t_preview: "Preview",
  t_exampleText: "Example Text",
  t_phoneNumbers: "Phone Numbers",
  t_enterPhoneNumbersSeparatedByCommas: "Enter phone number separated by commas.",
  t_enterEmailsSeparatedByCommas: "Enter emails separated by commas.",
  t_pleaseEnterAtLeastOnePhoneNumber: "Please enter at least one phone number.",
  t_pleaseEnterAMessage: "Please enter a massage.",
  t_messagesHaveBeenDeliveredSuccessfully: "Messages have been delivered successfully.",
  t_thereWasAProblemDeliveringYourMessages: "There was a problem delivering your messages.",
  t_displayName: "Display Name", // SmsSettings //
  t_senderNumber: "Sender Number",
  t_accountCode: "Account Code",
  t_apiKey: "API Key",
  t_smsSettingList: "SMS Setting List",
  t_pattern: "Patterns", // SmsTemplate //
  t_patternName: "Pattern Name",
  t_smsText: "SMS Text",
  t_patternList: "Pattern List",
  t_callCenter: "Call Center", // Statistics //
  t_statistics: "Statistics",
  t_missedCall: "Missed Call",
  t_lunch: "Lunch",
  t_break: "Break",
  t_AVGInboundLengt: "AVG Inbound Lengt",
  t_AVGOutboundLengt: "AVG Outbound Lengt",
  t_totalInbound: "Total Inbound",
  t_totalOutbound: "Total Outbound",
  t_remainingBreakTime: "Remaining Break Time",
  t_statusTime: "Status Time",
  t_totalActionCounts: "Total Action Counts",
  t_phonesUpdate: "Phones Update",
  t_phonesinfoUpdate: "Update Phones Details",
  t_important: "Important",
  t_apointment: "Apointment",
  t_reminder: "Reminder",
  t_personal: "Personal",
  t_personaly: "Personaly",
  t_waitingInQueue: "Waiting In Queue",
  t_numberofwaitingininstantqueue: "Number of waiting in instant queue",
  t_allcallrecordsfortoday: "All call records for today",
  t_collectionTarget: "Collection Target",
  t_collectedMade: "collected Made",
  t_workingDay: "Working Day",
  t_present: "Present",
  t_absent: "Absent",
  t_actionDate: "Action D.",
  t_inCallOutbound: "In Call Outbound (Out)",
  t_inCallInbound: "In Call Inbound (In)",
  t_ringing: "Ringing",
  t_noAnswer: "No Answer",
  t_promiseToPay: "Promise To Pay",
  t_refuseToPay: "Refuse To Pay",
  t_messageLeft: "Message Left",
  t_busy: "Busy",
  t_statusCategory: "Status Category", // StatusManagement //
  t_statusCategorys: "Status Categorys",
  t_statusName: "Status Name",
  t_categoryKey: "Category Key",
  t_categories: "Categories",
  t_changeable: "Changeable",
  t_onlySPVCanChange: "Only SPV Can Change",
  t_active: "Active",
  t_statusList: "Status List",
  t_online: "Online",
  t_offline: "Offline",
  t_other: "Other",
  t_timeConditionName: "Time Condition Name", // TimeConditionManagement - List.vue
  t_startDay: "Start Day",
  t_startingDayOfMonth: "Starting Day Of Month",
  t_monthStart: "Month Start",
  t_monthStartDay: "Month Star tDay",
  t_monthEndDay: "Month End Day",
  t_monthEnd: "Month End",
  t_endDay: "End Day",
  t_endingDayOfMonth: "Ending Day Of Month",
  t_timeConditions: "Time Conditions",
  t_monday: "Monday",
  t_tuesday: "Tuesday",
  t_wednesday: "Wednesday",
  t_thursday: "Thursday",
  t_friday: "Friday",
  t_saturday: "Saturday",
  t_sunday: "Sunday",
  t_january: "January",
  t_february: "February",
  t_march: "March",
  t_april: "April",
  t_may: "May",
  t_june: "June",
  t_july: "July",
  t_august: "August",
  t_september: "September",
  t_october: "October",
  t_novenber: "November",
  t_december: "December",
  t_theBeginingConditionCanNotBeGraterThanEndingCondition: "The begining condition can not be grater than ending condition.",
  t_userInformations: "User Informations",
  t_import: "Import", // UsersManagement //
  t_importUsers: "Import Users", // UsersManagement //
  t_importRecords: "Import Records", // UsersManagement //
  t_userList: "User List",
  t_tenantName: "Tenant Name...",
  t_recordingExport: "Recording Export", // VoiceExport.vue
  t_recordingRemove: "Recording Remove", // VoiceExport.vue
  t_filters: "Filters",
  t_tags: "Tags",
  t_nameType: "Name Type", // VoiceExport.vue
  t_youCanAddCommaBetweenEmailAddressesToAddMore: "You can add comma between email addresses to add more",
  t_startDate: "Start Date",
  t_endDate: "End Date",
  t_startTime: "Start Time",
  t_namePattern: "Name Pattern",
  t_targetMail: "Target Email",
  t_whenTheFilesAreReadyYouWillbeSentAnEMail: "When the files are ready you will be sent an email.",
  t_fileType: "File Type",
  t_theProcessHasBeenStartedItWillBeSentByEmailWhenItIsCompleted: "The process has been started, it will be sent by email when it is completed.",
  t_thereWasAProblemStartingTheProcess: "There was a problem starting the process",
  t_process: "Process", // VSPR //
  t_deactiveCustomer: "Deactive Customer",
  t_activeCustomer: "Active Customer",
  t_assigneManager: "Assigne Manager",
  t_conclude: "Conclude",
  t_addingNote: "Adding Note",
  t_deleteCallRecord: "Delete Call Record",
  t_matchingColumn: "Matching Column", // components - VSPR //
  t_pleaseFillTheAllNecessaryAreas: "Please fill the all necessary areas.",
  t_thereWasAProblemWhileAssigning: "There was a problem while assigning.",
  t_doesNotMatchAnyCustomers: "Does not match any customers.",
  t_matchingColumnMustBeTheSameInTheExcelColumn: "Matching column must be the same in the excel column.",
  t_AProblemOccurredwhileProcessing: "A problem occured while processing.",
  t_customersHaveBeenSuccessfullyUpdated: "Customers have been successfully updated.",
  t_customersHaveBeenSuccessfullyDeleted: "Customers have been successfully deleted.",
  t_processSuccess: "Process Success",
  t_assigne: "Assigne", // AssignManager //
  t_manager: "Manager",
  t_thereWasAProblemWhileAssigningManager: "There was a problem while assiging manager.",
  t_managerHaveSuccessfullyBeenAssigned: "Manager have successfully been assigned.",
  t_deactiveCustomersHaveSuccessfullyBeenDone: "Deactive customers have successfully been done.", // CloseCustomer //
  t_VSPRCloseCustomer: "CloseCustomer",
  t_VSPROpenCustomer: "Open Customer",
  t_aProblemOccurredWhileConcluding: "A problem occurred while concluding.",
  t_customersHaveSuccessfullyBeenConcluded: "Customers have successfully been concluded.",
  t_thereWasAProblemActivatingCustomers: "There was a probleö activating customers.",
  t_customersHaveBeenSuccessfullyActivated: "customers have been successfully activated.",
  t_deleteCallRecordings: "Delete call recordings",
  t_fileHasBeenImportedSuccessfully: "File has been imported successfully.",
  t_makeAnAnnouncement: "Make An Announcement", // Chat //
  t_sendASouffle: "Send A Souffle",
  t_startChat: "Start Chat",
  t_open: "Open",
  t_conversations: "Conversations",
  t_announcementHistory: "Announcement History",
  t_announcementPanel: "Announcement Panel",
  t_startAconversation: "Start A Conversation",
  t_youGotAPrivateMessage: " You Got A Private Message.",
  t_startANewConversation: "Start A New Conversation",
  t_updatePassword: "Update Password", // Navbar //
  t_PasswordCondition: "Your password must be at least 6 characters long, and must contain at least 1 letter and 1 number.",
  t_lastAccountEvent: 'Last Account Event',
  t_changePassword: 'Change Password',
  t_again: "Again",
  t_currentPassword: "Current Password",
  t_confirmPassword: "New Password (Repeat)",
  t_newPassword: "New Password",
  t_pleaseSelectfinishCode: "Please select finish code...",
  t_survey: "Survey",
  t_passwordmustContainAtLeast1LetterAnd1Number: "Password must contain at least 1 letter and 1 number.",
  t_enteredpasswordsDoNotMatch: "Entered passworlds do not match.",
  t_passwordMustBeAtLeast6CharactersLong: "Password must be at least 6 characters long.",
  t_YourPasswordCannotBeTheSameAsYourOldPassword: "your password can not be the same as your old password.",
  t_PasswordHasBeenchangedSuccessfully: "Password has been changed successfully.",
  t_youHaveReconnectedToTheService: "You have reconnected to the service.",
  t_yourServiceConnectionHasBeenLost: "Your service connection has been lost.",
  t_ACWNothingSelected: "ACW nothing selected",
  t_ACWTimeOut: "ACW time out",
  t_calendar: "Calendar", // Calendar //
  t_color: "Color",
  t_view: "View",
  t_allDays: "All Days",
  t_addEvent: "Add Event",
  t_eventName: "Event Name",
  t_eventNote: "Event Note",
  t_eventDate: "Event Date",
  t_eventTime: "Event Time",
  t_displayOnCRM: "Display on CRM",
  t_monthly: "Monthly",
  t_weekly: "Weekly",
  t_daily: "Daily",
  t_mon: "Mon",
  t_tue: "Tue",
  t_wed: "Wed",
  t_thu: "Thu",
  t_fri: "Fri",
  t_sat: "Sat",
  t_sun: "Sun",
  t_monSat: "Mon - Sat ",
  t_sunSat: "Sun - Sat",
  t_monFri: "Mon - Fri",
  t_monWedFri: "Mon, Wed, Fri",
  t_eventhasBeenCreatedSuccessfully: "Event has been successfully created.",
  t_liveDashboard: "Live Dashboard", // Menu //
  t_mAlerts: 'Alerts',
  t_systemManagement: "System Management",
  t_operationManagement: "Operation Management",
  t_integrations: "Integrations",
  t_eMail: "E-Mail",
  t_qVoiceMail: "Voice Mail",
  t_speechRule: 'Speech Rule',
  t_addSpeechRule: 'Add Speech Rule',
  t_lines: "Lines",
  t_routes: "Routes", // System Management ->  Main
  t_vIVR: "Variable IVR",
  t_backup: "Backup",
  t_providers: "Providers",
  t_mVoice: "Voice",
  t_reachedCalls: "Reached Calls",
  t_unreachedCalls: "Unreached Calls",
  t_missedCalls: "Missed Calls",
  t_outcomingCall: "Outcoming Call",
  t_inboundCall: "Inbound Call",
  t_incomingCallAvg: "Incoming Call Avg",
  t_outgoingCallAvg: "Outgoing Call AVg",
  t_show: "Show",
  t_entries: "Entries",
  t_addRecord: "Add Record",
  t_showing: "Showing",
  t_title: "Title",
  t_clear: "Clear",
  t_perPage: "Per Page",
  t_uploadFileOnClick: "Upload file on click...",
  t_delete: "Delete",
  t_noEditedRecord: "No edited record.",
  t_noRecord: "No Record",
  t_errorFetchData: "Error Fetch Data.",
  t_backToList: "Back To List",
  t_deleteRecord: 'Delete Record',
  t_updateRecord: 'Upload Record',
  t_archivedMessages: "Archived Messages",
  t_lastlogin: "Last Login",
  t_lastLogin: "Last Login",
  t_avaragequalitypoint: "Avarage Quality Point",
  t_titles: 'Titles',
  t_searchPlaceHolder: 'Search with: Name, Customer No, ID No, or Phone...',
  t_customerId: 'Customer ID',
  t_breakStatus: 'Break Status',
  t_targetStatus: 'Goal Status',
  t_mrAgentAssign: 'Agent Assign',
  t_dataUpload: 'Data Upload',
  t_customerList: 'Customer List',
  t_customerListByPhone: 'Customer List By Phone',
  t_customerListByProduct: 'Customer List By Product',
  t_customerListByCSV: 'Customer List By CSV',
  t_vehicle: 'Vehicle',
  t_landRegistry: 'Land Registry',
  t_SSK: 'SSK',
  t_mernis: 'Mernis',
  t_gsmQuery: 'GSM Query',
  t_negotiation: 'Negotiation',
  t_products: 'Products',
  t_phones: 'Phones',
  t_hourlyGraphic: 'Hourly Graphic',
  t_deleteFilter: 'Delete Filter',
  t_addFilter: 'Add Filter',
  t_clearFilter: 'Clear Filter',
  t_addNewRecord: 'Add New Record',
  t_selectableLines: "Selectable Lines",
  t_selectedLines: "Seleted Lines",
  t_restartService: 'Are you sure you want to restart service?',
  t_restartServiceSuccessfully: 'Service has been restarted successfully..',
  t_id: 'Id',
  t_goal: 'Goal',
  t_goalManagementDescription: "Graphic view",
  t_smscount: ' amount of SMS will be sent. Are you sure?',
  t_call: 'Call',
  t_dialerCall: "Call",
  t_clearFilters: "Clear Filters",  // CRM - Main.vue //
  t_management: "Management",
  t_customerInformation: "Customer Information",
  t_extraInformation: "Extra Information",
  t_hide: "Hide",
  t_addAction: "Add Action",
  t_addNote: "Add Note",
  t_landLine: "Land Line",
  t_addFinishCode: "Add Finish Code",
  t_addProductAction: "Add Product Action",
  t_actionDate2: "Action D.",
  t_installmentCount: "Installment Count",
  t_installmentAmount: "Installment Amount",
  t_relationTo: "Owner",
  t_isApproved: "Is Approved?",
  t_addData: "Add Data",
  t_sendSMS: "Send SMS",
  t_sendEmail: "Send Email",
  t_filterTheResults: "Filter The Results",
  t_filterBy: "Filter By",
  t_pleaseWriteSomethingToSeeYourOptions: "Please write something to see your options.",
  t_columnVisibilityOptions: "Column Visibility Options",
  t_showHide: "(Show/Hide)",
  t_agentAssign: 'Agent Assign',
  t_assigmnentType: "Assigment Type",
  t_contactPool: 'Contact Pool',
  t_sender: "Sender",
  t_shuffle: "Shuffle",
  t_assignForProductAgent: 'Should a Representative Be Assigned for Products?',
  t_progresingDate: "Progresing D.",
  t_allUnassignedCasesWillBeAssignedToSelectedAgentsAreYouSure: "All unassigned cases will be assigned to selected agents, are you sure?",
  t_amountOfCasesWillBeUpdatedAreYouSure: "Amount of cases will be updated, are you sure?",
  t_casesCouldNotBeAssigned: "cases could not be assigned.",
  t_customersHaveSuccessfullyBeenAssigned: "customers have successfully been assigned.",
  t_error: "Error",
  t_failed: "Failed",
  t_reportDetails: 'Report Details', // Reports.vue
  t_today: "Today", // Reports.vue
  t_yesterday: "Yesterday", // Reports.vue
  t_last7Days: "Last 7 Days", // Reports.vue
  t_last30Days: "Last 30 Days", // Reports.vue
  t_betweenDates: "Between Dates", // Reports.vue
  t_reports: 'Reports', //Main.vue
  t_reportType: 'Report Type',
  t_pleaseSelect: "Please Select",
  t_callDetails: "Call Details",
  t_mrInboundCallDetails: "Inbound Call Details",
  t_mrSL30Daily: "SL30 Daily",
  t_mrSL30Monthly: "SL30 Monthly",
  t_mrSL30Hourly: "SL30 Hourly",
  t_mrAbandonedCalls: "Abandoned Calls",
  t_mrAfterWorkInboundCalls: "After Work Inbound Calls",
  t_mrTotalCalls: "Total Calls",
  t_extraTime: "Extra Time",
  t_giveExtraTime: "Give Extra time?",
  t_time: "Time (sec)",
  t_mrCallDurationDetails: "Call Duration Details",
  t_mrCallDurationSummary: "Call Duration Summary",
  t_mrInboundCallDaily: "Inbound Call Daily",
  t_mrInboundCallHourly: "Inbound Call Hourly",
  t_surveyDetails: "Survey Details",
  t_mrAgent: "Agent",
  t_mrAgentActivity: "Agent Activity",
  t_mrAgentCallCountsDaily: "Agent Call Counts Daily",
  t_mrAgentCallCountsByDate: "Agent Call Counts By Date",
  t_mrAgentWork: "Agent Work",
  t_mrAgentCalls: "Agent Calls",
  t_mrAgentDaily: "Agent Daily",
  t_mrAgentHourly: "Agent Hourly",
  t_mrAgentCalendar: "Agent Calendar",
  t_mrIvnDialerCallAnalyze: "Ivn Dialer Call Analyze",
  t_mrIvnDialerCallDetails: "Ivn Dialer Call Details",
  t_called: "Called", // Dashboard.vue
  t_closed: "Closed",
  t_backedupFiles: "Backed Up Files", // Backup.vue
  t_pleaseSelectTheFileToUpload: "Please select the file to upload...",
  t_createBackup: "Create Back Up",
  t_exportToDatabase: "Export To Database",
  t_filter: "Filter", // GLOBAL //
  t_settings: "Settings",
  t_action: "Action",
  t_note: "Note",
  t_column: "Column",
  t_value: "Value",
  t_operator: "Operator",
  t_users: "Users",
  t_agents: "Agents",
  t_date: "Date",
  t_agent: "Agent", // Home.vue
  t_finishCode: "Finish Code",
  t_direction: "Direction",
  t_defaultRoute: "Default Route",
  t_editSelected: "Edit Selected",
  t_crm: 'CRM',
  t_dialer: 'Dialer', // Main.vue - VoiceExport.vue
  t_goalManagement: 'Goal Management',
  t_line: 'Line',
  t_dialPlans: 'Dial Plans',
  t_queues: 'Queues',
  t_projects: 'Projects',
  t_statuses: 'Statuses',
  t_announce: 'Announce',
  t_createScript: 'Create Script...',
  t_queueTimeOut: 'Queue Time Out',
  t_detinationType: 'Detination Type',
  t_detinationTarget: 'Goal',
  t_showInDashboard: 'Show In Dashboard?',
  t_reject: 'Reject',
  t_isProductFinishCode: 'Is product finish code?',
  t_queueGroup: 'Queue Group', //Home.vue
  t_selectableQueues: "Selectable Queues",
  t_selectedQueues: "Selected Queues",
  t_isHoldSound: 'Is Hold Sound?',
  t_waitTime: 'Wait Time',
  t_isReached: 'Is Reached?',
  t_addCustomer: 'Add Customer',
  t_isAutoEmail: 'Is Auto E-Mail?',
  t_emailTemplate: 'E-Mail Tempalte',
  t_account: 'Account',
  t_performance: 'Performance',
  t_security: 'Security',
  t_emailDelayAmount: 'E-Mail Delay Amount',
  t_isAutoSMS: 'Is Auto SMS?',
  t_smsDelayAmount: 'SMS Delay Amount',
  t_autoAnswer: 'Auto Answer',
  t_isUnassign: 'Is Unassign?',
  t_isPassive: 'Is Passive?',
  t_setAttemptsByPhone: 'Set Attempts By Phone',
  t_recordingFile: 'Record',
  t_callStrategy: 'Call Strategy',
  t_sortCriteria: 'Sort Criteria',
  t_internationalCall: 'International Call',
  t_dialerPrecall: 'Dialer Precall',
  t_variable: 'Variable',
  t_variableLength: 'Variable Length',
  t_reportFiles: 'Report Files', // Main.vue
  t_schedule: 'Schedule',
  t_scheduler: 'Scheduler',
  t_schedulerTasks: 'Scheduler Tasks',
  t_schedulerCategory: 'Scheduler Category',
  t_reportCategory: 'Report Category',
  t_lastXDays: 'Last X Days',
  t_communicationType: 'Communication Type',
  t_communicationSrc: 'Communication Source',
  t_communicationTitle: 'Communication Title',
  t_to: '(To)',
  t_cc: '(CC)',
  t_youCanAddCommaBetweenEmailAddressesToaddMore: "You can add comma between E-Mail addresses to add more",
  t_email: 'E-Mail',
  t_mobileNotification: 'Mobile Notification',
  t_callPlaced: 'Call Placed',
  t_callWentToAgent: 'call Went To Agent',
  t_callJoinedQueue: 'In Queue',
  t_callPickedUp: 'Call Picked Up',
  t_callRejected: 'CallRejected',
  t_schedulerTime: 'Scheduler Time',
  t_schedulerTimeString: 'Scheduler Time String',
  t_creator: 'Creator',
  t_nextRunTime: 'Next Run Time',
  t_externalChat: 'External Chat',
  t_start: "Start", // IvnDialer //
  t_selectAll: "Select All", // CRM - Main.vue - Home.vue //
  t_clear: "Clean Selections", // CRM - Main.vue - Home.vue//
  t_clearAll: "Clean All", // Main.vue
  t_queue: "Queue", // Home.vue - Reports.Vue
  t_announcement: "Announcement", //ProfileQueueManagement - Home.vue //
  t_duration: "Duration", //Statistics - Home.vue
  t_productivity: "Productivity", //Statistics - Home.vue
  t_status: "Status", // Home.vue
  t_usedBreakTime: "Used Break", //Statistics - Home.vue 
  t_answered: "Answered",  // Statistic.vue - Wallboard.vue - Home.vue
  t_successfullCall: "Successful", //Statistics - Home.vue - Dialer.vue
  t_unsuccessfullCall: "Unsuccessful", //Statistics - Home.vue - Dialer.vue
  t_export: "Export", // Home.vue - List.vue - Reports.vue
  t_total: "Total", // Dialer.vue
  t_rate: "Rate (%)", // Statistic.vue - Home.vue - Dialer.vue
  t_send: "Send", //SmsFilter
  t_next: 'Next',
  t_previous: 'Previous',
  t_classification: "Classification", // List.vue - ListAddNew.vue
  t_inCall: "In Call", // Wallboard.vue - Home.vue
  t_paused: "Paused",  // Wallboard.vue - Home.vue
  t_profile: "Profile",
  t_no: "No",
  t_yes: "Yes",
  t_report: 'Report',
  t_actions: "Actions", // All
  t_cancel: "Cancel", // All
  t_save: "Save", // All
  t_category: "Category", // All
  t_project: "Project", // All
  t_close: "Close", // All
  t_name: "Name", // All
  t_sms: 'SMS', // All
  t_general: 'General',
  t_loginPageImages: 'Login Pages Images',
  t_browse: 'Browse',
  t_thisMonth: 'This Month',
  t_lastMonth: 'Last Month',
  t_yourReportAddToReportQueue: 'Your Report Has Been Added To Report Queue',
  t_notification: 'Notification',
  t_notifications: 'Notifications',
  t_pleaseSelectReport: 'Please Select Report',
  t_host: 'Host',
  t_subject: 'Subject',
  t_isSendMailToCustomer: 'Is Send Mail To Customer?',
  t_surveyCancel: 'Cancel Survey',
  t_surveyApprove: 'Approve Survey',
  t_sendType: "Send Type",
  t_info: "Info",
  t_pleaseSelectTimeCondition: 'Please Select Time Condition',
  t_crmWhiteColorInfo: 'No Attempts',
  t_crmInfoColorInfo: 'Callable',
  t_crmWarningColorInfo: 'Uncallable',
  t_crmSecondaryColorInfo: 'Closed',
  t_crmDangerColorInfo: 'No Attempts & Closed',
  t_nextCustomer: 'Next Customer',
  t_lineChangeInfo: 'Please save your campaigns using the relevant line group.',
  t_deleteData: 'Delete Data',
  t_closedByDailyAttempts: 'Closed By Daily Attempts',
  t_closedByTotalAttempts: 'Closed By Total Attempts',
  t_resetScheduledCalls: 'Reset Scheduled Calls',
  t_resetDailyAttempts: 'Reset Daily Attempts',
  t_resetTotalAttempts: 'Reset Total Attempts',
  t_spokenCall: 'Spoken',
  t_spokenCallRate: 'Spoken%',
  t_nonSpokenCall: 'NonTalked',
  t_nAttemptsCall: 'NoAttempts',
  t_attemptsRate: 'Attempts%',
  t_reachRate: 'Reach%',
  t_totalCallInfo: 'Total number of calls dialed',
  t_spokenCallInfo: 'Number of calls the agent has spoken',
  t_spokenRateInfo: 'Ratio of contacted calls to number of calls. \nFormula : Spoken / Total Calls',
  t_noSpokenCallInfo: 'Number of calls contacted but not spoken. No Answer and Busy calls',
  t_noAttemptsCallInfo: 'Number of calls transmitted by the system to the operator but terminated without the operator contacting the called number',
  t_attemptsRateInfo: 'The rate at which calls forwarded by the system to the operator contacted the called number. \nFormula: Total - No Attempts Calls / Total Calls',
  t_reachRateInfo: 'The rate at which called numbers are connected to the queue. \nFormula : Spoken Calls + Abandon Calls / Total Calls',
  t_username: 'Username',
  t_examples: 'Examples',
  t_cron: 'Cron',
  t_cron1: "Every minute",
  t_cron2: "Every hour",
  t_cron3: "Every day at midnight",
  t_cron4: "Every day at 5:00 PM",
  t_cron5: "Every Sunday at midnight",
  t_cron6: "Every month on the first day at midnight",
  t_cron7: "Every year on the first day at midnight",
  t_cron8: "Every weekday at midnight",
  t_cron9: "Every month on the 15th day at midnight",
  t_cron10: "Every year on the first Monday at midnight",
  t_cron11: "Every year on the 15th day of January, June, and December at midnight",
  t_cron12: "Every month on the first weekday at midnight",
  t_cron13: "Every weekend at midnight",
  t_alert: 'Alert',
  t_lessThan: 'Less Than',
  t_lessThanOrEqual: 'Less Than Or Equal',
  t_moreThanOrEqual: 'More Than Or Equal',
  // Report Fields
  t_rExtra: 'Extra Informations',
  t_rSender: 'Sender',
  t_rMessage: 'Message',
  t_rEvaluateEvent: 'Evaluate Event',
  t_rEvaluateTime: 'Evaluate Time',
  t_rQualityFormName: 'Quality Form Name',
  t_callAnalyze: 'Call Analyze',
  t_rObjectionEvent: 'Objection Event',
  t_rPunishEvent: 'Punish Event',
  t_rQualityPoint: 'Quality Point',
  t_rMailSender: "Mail Sender",
  t_rSubject: "Subject",
  t_rBody: "Body",
  t_rTo: 'To',
  t_rCC: 'CC',
  t_rEndTime: 'EndTime',
  t_rRemoteNumber: 'Remote Number',
  t_rAgent: 'Agent',
  t_rDirection: 'Direction',
  t_rQueue: 'Queue',
  t_rVerdict: 'Verdict',
  t_rActionDate: 'Action Date',
  t_rNote: 'Note',
  t_rConnectedLength: 'Connected Length',
  t_rQueueWaitLength: 'QueueWait Length',
  t_rHoldLength: 'Hold Length',
  t_rRecordingFile: 'Recording File',
  t_rTerminateSide: 'Terminate Side',
  t_rRecordingFileName: 'Recording File Name',
  t_rLineName: 'Line Name',
  t_rClassification: 'Classification',
  t_rCampaign: 'Campaign',
  t_rInteractionID: 'Interaction ID',
  t_rStartTime: 'Start Time',
  t_rMüşteriNo: 'Customer No',
  t_rAdıSoyadı: 'Customer Name',
  t_rIsCalledBack: 'Is Called Back',
  t_rCalledBackedStartTime: 'Called Backed Start Time',
  t_rCalledBackedQueue: 'Called Backed Queue',
  t_rUsername: 'Username',
  t_rTotal: 'Total',
  t_rAvgCallTime: 'Avg Call Time',
  t_rOnlineTime: 'Online Time',
  t_rInCallTime: 'In Call Time',
  t_rInboundSuccessSL: 'Inbound Success SL',
  t_rOutboundSuccess: 'Outbound Success',
  t_rOutboundFail: 'Outbound Fail',
  t_rTotalWaitTime: 'Total Wait Time',
  t_rAvgWaitTime: 'Avg Wait Time',
  t_rMaxWaitTime: 'Max Wait Time',
  t_rAgentMissed: 'Agent Missed',
  t_rInitMoment: 'Init Moment',
  t_rLocalNumber: 'Local Number',
  t_rCount: 'Count',
  t_rDate: 'Date',
  t_rCustomerID: "Customer ID",
  t_rProductID: "Product ID",
  t_rInboundSuccessRate: 'Inbound Success Rate',
  t_rDialerSuccess: 'Dialer Success',
  t_rDialerFail: 'Dialer Fail',
  t_rDialerTotal: 'Dialer Total',
  t_rDialerTotalTalkTime: 'Dialer Total Talk Time',
  t_rDialerAvgTalkTime: 'Dialer Avg Talk Time',
  t_rTotalSuccess: 'Total Success',
  t_rTotalTalkTime: 'Total Talk Time',
  t_rTotalAvgTalkTime: 'Total Avg Talk Time',
  t_rTotalCustomer: 'Total Customer',
  t_rCallable: 'Callable',
  t_rUnCallable: 'UnCallable',
  t_rZeroAttempts: 'Zero Attempts',
  t_rStartDate: 'Start Date',
  t_rAgentCount: 'Agent Count',
  t_rInboundAgentTotal: 'Inbound Agent Total',
  t_rInboundSuccess: 'Inbound Success',
  t_rInboundSuccessSLA30: 'Inbound Success SLA30',
  t_rInboundSuccessSL120: 'Inbound Success SL120',
  t_rInboundSuccessSLA120: 'Inbound SuccessS LA120',
  t_rInboundFailSL30: 'Inbound Fail SL30',
  t_rInboundFailSLA30: 'Inbound Fail SLA30',
  t_rInboundSuccessSLA30AvgWaitTime: 'Inbound Success SLA30 Avg Wait Time',
  t_rInboundSuccessSLA120AvgWaitTime: 'Inbound Success SLA120 Avg Wait Time',
  t_rAvgWaitTimeSLA30: 'Avg Wait Time SLA30',
  t_rServiceLevel: 'Service Level',
  t_rLogin: 'Login',
  t_rLogout: 'Logout',
  t_rTotalConnectedLength: 'Total Connected Length',
  t_rAvgConnectedLength: 'Avg Connected Length',
  t_rTotalSuccessCount: 'Total Success Count',
  t_rBaseRemoteNumber: 'Base Remote Number',
  t_rTotalCall: 'Total Call',
  t_rSuccessCall: 'Success Call',
  t_rFailCall: 'Fail Call',
  t_rAMD: 'AMD',
  t_rTotalCallLength: 'Total Call Length',
  t_rFirstCallTime: 'First Call Time',
  t_rLastCallTime: 'Last Call Time',
  t_rAbandon: 'Abandon',
  t_rSaleCall: 'Sale Call',
  t_rEventTime: 'Event Time',
  t_rEvent: 'Event',
  t_rPrevStatus: 'Prev Status',
  t_rPrevReason: 'Prev Reason',
  t_rPrevStatusLength: 'Prev Status Length',
  t_rInboundTotal: 'Inbound Total',
  t_rInboundSL30: 'Inbound SL30',
  t_rInboundAnswerRate: 'Inbound Answer Rate',
  t_rInboundAvgCallTime: 'Inbound Avg Call Time',
  t_rOutboundAvgCallTime: 'Outbound Avg Call Time',
  t_rInterval: 'Interval',
  t_rInboundTotalCallTime: 'Inbound Total Call Time',
  t_rOutboundTotalCallTime: 'Outbound Total Call Time',
  t_rDayName: 'Day Name',
  t_rInboundSuccessSL30: 'Inbound Success SL30',
  t_rInboundFailSL10: 'Inbound Fail SL10',
  t_rInboundSuccessRateSL30: 'Inbound Success Rate SL30',
  t_rInboundSuccessSL30Rate: "Inbound Success SL30 Rate",
  t_rAgentPerformanceSummary: "Agent Performance Summary",
  t_rInboundFailRate: 'Inbound Fail Rate',
  t_rInboundAvgTalkTime: 'Inbound Avg Talk Time',
  t_rInboundTotalTalkTime: 'Inbound Total Talk Time',
  t_rInboundAvgAnswerTime: 'Inbound Avg Answer Time',
  t_rOutboundTotal: 'Outbound Total',
  t_audioEncoding: "Audio Encoding",
  t_gender: "Gender",
  t_token: "Token",
  t_showTCKN: "Show Identifier",
  t_totalCallTime: "Total Talk Time",
  t_avgCallTime: "Avg. Talk Time",
  t_avgAnswerTime: "Avg. Answer Time",
  t_avgHoldTime: "Avg. Hold Time",
  t_avgAcwTime: "Avg. Acw Time",
  t_totalCallTimeDescrition: "KPI",
  t_avgCallTimeDescrition: "KPI",
  t_avgAnswerTimeDescrition: "KPI",
  t_avgHoldTimeDescrition: "KPI",
  t_avgAcwTimeDescrition: "KPI",
  t_callRecords: "Call Records",
  t_secondLine: "Second Line",
  t_changeMode: "Change Mode",
  t_showNotConnected: "Show Not Connected",
  t_fullScreen: "Full Screen",
  t_noRecordOnQueue: "No Records On Queue",
  t_webChat: "Web Chat",
  t_whatsapp: "Whatsapp",
  t_instagram: "Instagram",
  t_messenger: "Messenger",
  t_events: "Events",
  t_rOutboundAvgTalkTime: 'Outbound Avg Talk Time',
  t_rOutboundTotalTalkTime: 'Outbound Total Talk Time',
  t_rCreateDate: 'Create Date',
  t_rAssignedAgent: 'Assigned Agent',
  t_rLine: 'Line',
  t_darkMode: "Dark Mode",
  t_lightMode: "Light Mode",
  t_rSuccessCalls: 'Success Calls',
  t_rSuccessRate: 'Success Rate',
  t_rFailCalls: 'Fail Calls',
  t_rHour: 'Hour',
  t_rIvrMissed: 'Ivr Missed',
  t_rInboundFail: 'Inbound Fail',
  t_rAgentSentimentPositiveSegmentCount: 'Agent Sentiment Positive Segment Count',
  t_rAgentSentimentPositiveScore: 'Agent Sentiment Positive Score',
  t_rAgentSentimentPositiveTokenCount: 'Agent Sentiment Positive Token Count',
  t_rAgentSentimentNegativeSegmentCount: 'Agent Sentiment Negative Segment Count',
  t_rAgentSentimentNegativeScore: 'Agent Sentiment Negative Score',
  t_rAgentSentimentNegativeTokenCount: 'Agent Sentiment Negative Token Count',
  t_rAgentSentimentTotalScore: 'Agent Sentiment Total Score',
  t_rAgentSentimentPositiveSegmentRate: 'Agent Sentiment Positive Segment Rate',
  t_rAgentSentimentNegativeSegmentRate: 'Agent Sentiment Negative Segment Rate',
  t_rAgentSentimentNotrSegmentRate: 'Agent Sentiment Notr Segment Rate',
  t_rCustomerSentimentPositiveSegmentCount: 'Customer Sentiment Positive Segment Count',
  t_rCustomerSentimentPositiveScore: 'Customer Sentiment Positive Score',
  t_rCustomerSentimentPositiveTokenCount: 'Customer Sentiment Positive Token Count',
  t_rCustomerSentimentNegativeSegmentCount: 'Customer Sentiment Negative Segment Count',
  t_rCustomerSentimentNegativeScore: 'Customer Sentiment Negative Score',
  t_rCustomerSentimentNegativeTokenCount: 'Customer Sentiment Negative Token Count',
  t_rCustomerSentimentTotalScore: 'Customer Sentiment Total Score',
  t_rCustomerSentimentPositiveSegmentRate: 'Customer Sentiment Positive Segment Rate',
  t_rCustomerSentimentNegativeSegmentRate: 'Customer Sentiment Negative Segment Rate',
  t_rCustomerSentimentNotrSegmentRate: 'Customer Sentiment Notr Segment Rate',
  t_rTotalDuration: 'Total Duration',
  t_rOverlappedSpeechRatio: 'Overlapped Speech Ratio',
  t_rOverlappedSpeechDuration: 'Overlapped Speech Duration',
  t_rOverlappedSilenceRatio: 'Overlapped Silence Ratio',
  t_rOverlappedSilenceDuration: 'Overlapped Silence Duration',
  t_rAgentSpeechRatio: 'Agent Speech Ratio',
  t_rAgentSpeechDuration: 'Agent Speech Duration',
  t_rAgentSpeakingRate: 'Agent Speaking Rate',
  t_rAgentInterruptCount: 'Agent Interrupt Count',
  t_rCustomerSpeechRatio: 'Customer Speech Ratio',
  t_rCustomerSpeechDuration: 'Customer Speech Duration',
  t_rCustomerSpeakingRate: 'Customer Speaking Rate',
  t_rCustomerInterruptCount: 'Customer Interrupt Count',
  t_rListens: 'Listens',
  t_rTotalEvaluations: 'Total Evaluations',
  t_rAutoEvaluations: 'Auto Evaluations',
  t_rEvaluations: 'Evaluations',
  t_rAvgAutoEvaluationsPoint: 'Avg Auto Evaluations Point',
  t_rAvgEvaluationsPoint: 'Avg Evaluations Point',
  t_rTotalCalls: 'Total Calls',
  t_rOverlappedSilence: 'Overlapped Silence',
  t_rOverlappedSilenceRateByTotalCalls: 'Overlapped Silence Rate By Total Calls',
  t_rOverlappedSilenceRateBySuccessCalls: 'Overlapped Silence Rate By Success Calls',
  t_rClosedByAgentCount: 'Closed By Agent Count',
  t_rClosedByCustomerCount: 'Closed By Customer Count',
  t_rClosedByAgentRateByTotalCalls: 'Closed By Agent Rate By Total Calls',
  t_rClosedByCustomerRateByTotalCalls: 'Closed By Customer Rate By Total Calls',
  t_rForbiddenWords: 'Forbidden Words',
  t_rForbiddenWordsRateByTotalCalls: 'Forbidden Words Rate By Total Calls',
  t_rForbiddenWordsRateBySuccessCalls: 'Forbidden Words Rate By Success Calls',
  t_rAgentNoSpeechCount: 'Agent No Speech Count',
  t_rAgentNoSpeechRateByTotalCalls: 'Agent No Speech Rate By Total Calls',
  t_rAgentNoSpeechRateBySuccessCalls: 'Agent No Speech Rate By Success Calls',
  t_rAvgAHT: 'Avg AHT',
  t_rMaxAHT: 'Max AHT',
  t_rMinAHT: 'Min AHT',
  t_rDuration: 'Duration',
  t_rListeningDuration: 'Listening Duration',
  t_rPlayCount: 'Play Count',
  t_rPauseCount: 'Pause Count',
  t_rIsMuted: 'Is Muted',
  t_rPlayBackChangeCount: 'Play Back Change Count',
  t_rPlayBackStartSpeed: 'Play Back Start Speed',
  t_rPlayBackEndSpeed: 'Play Back EndS peed',
  t_rManualForm: 'Manual Form',
  t_rManualFormPoint: 'Manual Form Point',
  t_rAutoForm: 'Auto Form',
  t_rAutoFormPoint: 'Auto Form Point',
  t_rQualityNote: "Quality Note",
  // Report Names
  t_rQualityDetails: "Quality Details",
  t_rCallDetails: "Call Details",
  t_rSmsHistory: 'SMS History',
  t_rMailHistory: 'Mail History',
  t_rSecondLineDetails: 'Second Line Details',
  t_rAbandonCalls: 'Abandon Calls',
  t_rAgentSummary: 'Agent Summary',
  t_rMissedCalls: 'Missed Calls',
  t_rQueueSL: 'Queue SL',
  t_rOutOfWork: 'Out Of Work',
  t_rQueueDaily: 'Queue Daily',
  t_rAgentDaily: 'Agent Daily',
  t_rQueueHourly: 'Queue Hourly',
  t_rAgentHourly: 'Agent Hourly',
  t_rVerdictByDate: 'Verdict By Date',
  t_rVerdictByDaily: 'Verdict By Daily',
  t_rVerdictByQueue: 'Verdict By Queue',
  t_rVerdictByAgent: 'Verdict By Agent',
  t_rClassificationByDate: 'Classification By Date',
  t_rClassificationByDaily: 'Classification By Daily',
  t_rClassificationByQueue: 'Classification By Queue',
  t_rClassificationByAgent: 'Classification By Agent',
  t_rCallCountByDate: 'Call Count By Date',
  t_rDataSummaryByQueue: 'Data Summary By Queue',
  t_rProductActionDetails: "Product Action Details",
  t_rInboundCallDetails: 'Inbound Call Details',
  t_rLoginLogout: 'Login Logout',
  t_rLocalNumberSL: 'Local Number SL',
  t_rCallTimeAndCountByDirection: 'Call Time And Count By Direction',
  t_rPozitifCallDetails: 'Pozitif Call Details',
  t_rActivity: 'Activity',
  t_rDailyCallValues: 'Daily Call Values',
  t_rHourlyCallValues: 'Hourly Call Values',
  t_rAgentPerformanceDaily: 'Agent Performance Daily',
  t_rAgentPerformanceMonthly: 'Agent Performance Monthly',
  t_rAgentPerformanceHourly: 'Agent Performance Hourly',
  t_rMonthlyCallValues: 'Monthly Call Values',
  t_rCallPerformanceByDate: 'Call Performance By Date',
  t_rDataSummaryByCreateDate: 'Data Summary By Create Date',
  t_rDataSummaryByAssignedAgent: 'Data Summary By Assigned Agent',
  t_rDataSummaryByDynamicFields: 'Data Summary By Dynamic Fields',
  t_rLinePerformance: 'Line Performance',
  t_rInboundCallValuesByHourly: 'Inbound Call Values By Hourly',
  t_rSurveyResult: 'Survey Result',
  t_rEmotionAnalysis: 'Emotion Analysis',
  t_rSpeechStatistics: 'Speech Statistics',
  t_rQualityByDate: 'Quality By Date',
  t_rQualityByAgent: 'Quality By Agent',
  t_rCallStatisticsByDate: 'Call Statistics By Date',
  t_rListeningDetails: 'Listening Details',
  t_rProject: 'Project',
  t_rIVR: 'IVR',
  t_rInQueue: "In Queue",
  t_rInProgress: "In Progress",
  t_reportName: "Report Name",
  t_dataCount: "Data Count",
  t_expand: "Expand",
  t_shrink: "Shrink",
  t_alertCategory: "Alert Category",
  t_targetCategory: "Goal Category",
  t_targetType: "Target Type",
  t_none: "None",
  t_seeExampleTimePatterns: "See Example Time Patterns",
  t_apply: "Apply",
  t_callList: 'Call List',
  t_pool: 'Pool',
  t_all: 'All',
  t_logs: 'Logs',
  t_totalAttempts: 'Total Attempts',
  t_insertDate: 'Call Date',
  t_actionTime: 'Action Time',
  t_assignDate: 'Assign Date',
  t_createDate: 'Create Date',
  t_createTime: 'Create Time',
  t_exAgent: 'Ex Agent',
  t_addExtraCustomerInfo: 'Add Extra Customer Info',
  t_primaryKey: 'Primary Key',
  t_secondaryKey: 'Secondary Key',
  t_qVoice: 'Voice',
  t_qVideo: 'Video',
  t_qText: 'Text',
  t_text: 'Text',
  t_interactionWeight: 'Interaction Weight',
  t_records: 'Records',
  t_qualityForm: 'Quality Form',
  t_keyWords: 'Key Words',
  t_criterions: 'Criteria',
  t_required: "Required",
  t_isChatFinishCode: 'Is Chat Finish Code?',
  t_minWaitInQueue: 'Min. Wait In Queue Time (s)',
  t_maxWaitInQueue: 'Max. Wait In Queue Time (s)',
  t_minDuration: 'Min. Duration (sn)',
  t_maxDuration: 'Max. Duration (sn)',
  t_rIsEvaluated: 'Is Evaluated?',
  t_rEvaluator: 'Evaluator',
  t_rEvaluatedStartTime: 'Evaluated Start Time',
  t_rEvaluatedEndTime: 'Evaluated End Time',
  t_rMinQualityPoint: 'Min Quality Point',
  t_rMaxQualityPoint: 'Max Quality Point',
  t_rQualityForm: 'Quality Form',
  t_rIsObjection: 'Is Objection?',
  t_multiple: 'Multiple Option',
  t_question: 'Question',
  t_minValue: 'Min. Value',
  t_maxValue: 'Max. Value',
  t_minTrueCount: 'Min. True Count',
  t_ordered: 'Ordered',
  t_minPoint: 'Min. Point',
  t_maxPoint: 'Max. Point',
  t_point: 'Point',
  t_selected: 'Selected',
  t_punish: 'Punish',
  t_pointRange: 'Point Range',
  t_answer2: 'Answer',
  t_openInNewWindow: 'Open In New Window',
  t_playbackRate: 'Playback Rate',
  t_isMailSendToAgent: 'Should I send an e-mail to the agent?',
  t_httpRequest: 'HTTP Request',
  t_tts: 'TTS',
  t_stt: 'STT',
  t_beepTone: "Beep Tone",
  t_model: 'Model',
  t_whatsapp: 'Whatsapp',
  t_readQR: 'Read QR',
  t_url: 'URL',
  t_headers: 'Headers (JSON)',
  t_body: 'Body (JSON)',
  t_useableVariables: 'Useable Variables',
  t_remoteNumber: 'Remote Number',
  t_hideVariableFromAgent: 'Hide Variable From Agent',
  t_provider: 'Provider',
  t_speaker: 'Speaker',
  t_isInList: 'Is In List',
  t_isNotInList: 'Is Not In List',
  t_isContains: 'Contains',
  t_isNotContains: 'Does Not Contain',
  t_IsConvergent: 'Is Convergent',
  t_IsNotConvrgent: 'Is Not Convrgent',
  t_changeAllQueues: 'Change All Queues',
  t_remove: 'Remove',
  t_objectToForm: 'Object To Form',
  t_objections: 'Objections',
  t_accept: 'Accept',
  t_rLastObjectingUser: 'Last Objecting User',
  t_evaluatedDaily: "Evaluated Daily",
  t_avgPointDaily: "Avg. Point Daily",
  t_evaluatedWeekly: "Evaluated Weekly",
  t_avgPointWeekly: "Avg. Point Weekly",
  t_evaluatedMonthly: "Evaluated Monthly",
  t_avgPointMonthly: "Avg. Point Monthly",
  t_evaluatedRecords: 'Evaluated Records',
  t_alerts: 'Alerts',
  t_standart: 'Standart',
  t_advanced: 'Advanced',
  t_quality: 'Quality',
  t_removeEvaluation: 'Romove Evaluation',
  t_openOnNewWindow: 'Open On New Window',
  t_undefined: 'Undefined',
  t_callTime: 'Call Time',
  t_serviceLevel: 'Service Level',
  t_abandonRate: 'Abandon Rate',
  t_analysis: "Analysis",
  t_configuration: "CONFIGURATION",
  t_callrecords: "Call Records",
  t_callstatisticsfortoday: "Call statistics for today",
  t_statusdurationfortoday: "Status duration for today",
  t_finishcodesofcallsfortoday: "Finish codes of calls for today",
  t_callAnswerTime: 'Call Answer Speed',
  t_noRouteToDestination: 'No Route To Destination',
  t_passwordSentToMailAdress: 'Your password sent to e-mail adress.',
  t_noEmailAdress: 'Your account has not e-mail adress, please contact your admin.',
  t_forgotPassword: 'Forgot Password?',
  t_saveRecord: 'Save Record?',
  t_isHttps: 'HTTPS',
  t_callStatistics: 'Call Statistics',
  t_addtCategory: 'Add Category',
  t_create: 'Create',
  t_questionCount: 'Question Count',
  t_minPts: 'Min Pts',
  t_maxPts: 'Max Pts',
  t_questionsAndAnswers: 'Questions & Answers',
  t_extensionRoutes: 'Extension Routes',
  t_informAgent: 'Inform Agent',
  t_autoCustomerCard: 'Auto Customer Card',
  t_downloadCallRecordings: 'Download Call Recordings',
  t_outboundCallApproval: 'Outbound Call Approval',
  t_callHistoryDownloadRecording: 'Call History - Download Recording',
  t_callHistoryListenRecording: 'Call History - Listen Recording',
  t_dialerReopenTotalAttempts: 'Dialer Reopen Total Attempts',
  t_dialerReopenDailyAttempts: 'Dialer Reopen Daily Attempts',
  t_dialerClearCallSchedules: 'Dialer Clear Call Schedules',
  t_showAllFinishCodes: 'Show All Finish Codes',
  t_updateLastFinishCode: 'Update Last Finish Code',
  t_showIVRInputs: 'Show IVR Inputs',
  t_hidePhoneNumbers: 'Hide Phone Numbers',
  t_addExtraData: 'Add Extra Data',
  t_script: 'Script',
  t_crmCall: 'Call',
  t_crmAddCustomer: 'Add Customer',
  t_displayAllRecords: 'Display All Records',
  t_editCustomer: 'Edit Customer',
  t_crmlistenRecording: 'CRM Listen Recording',
  t_showNoteDetails: 'Show Note Details',
  t_excelCustomer: 'Excel Customer',
  t_excelCustomerByPhone: 'Excel Customer By Phone',
  t_excelCustomerByProduct: 'Excel Customer By Product',
  t_showPhoneDetails: 'Show Phone Details',
  t_justShowOwnCustomers: 'Just Show Own Customers',
  t_dontSendCustomMessage: 'Cant Edit Message',
  t_keywordCategories: "Keyword Categories",
  t_keyWord: "Keyword",
  t_minOccurrence: "Min Occurrence",
  t_maxOccurrence: "Max Occurrence",
  t_inFirstSeconds: "In First Seconds",
  t_inLastSeconds: "In Last Seconds",
  t_logicCondition: "Logic Condition",
  t_reverse: "Reverse",
  t_and: "And",
  t_or: "Or",
  t_customer: "Customer",
  t_oneOfBoth: "One of Both",
  t_bothOfThem: "Both of Them",
  t_liveDashboards: 'LIVE DASHBOARD',
  t_tools: 'TOOLS',
  t_admin: 'ADMIN',
  t_useDatesForEvaluateDate: 'Use Date Filter For Evaluate Date',
  t_menuLineGroups: 'Menu - Line Groups',
  t_menuDialPlans: 'Menu - Dial Plans',
  t_menuIVR: 'Menu - IVR',
  t_menuAnnouncements: 'Menu - Announcements',
  t_menuRoutes: 'Menu - Routes',
  t_menuTimeCondition: 'Menu - Time Condition',
  t_menuUsers: 'Menu - Users',
  t_menutExtensionRoutes: 'Menu - Extension Routes',
  t_menuQueues: 'Menu - Queues',
  t_menuProjects: 'Menu - Projects',
  t_menutStatuses: 'Menu - Statuses',
  t_menutFinishCodes: 'Menu - Finish Codes',
  t_menuBlackList: 'Menu - Black List',
  t_menuProfiles: 'Menu - Profiles',
  t_menuRecordingExport: 'Menu - Recording Export',
  t_menuSms: 'Menu - SMS',
  t_menuReports: 'Menu - Reports',
  t_menuGoalManagement: 'Menu - Goal Management',
  t_menuDialer: 'Menu - Dialer',
  t_menuLiveDashboard: 'Menu - Live Dashboard',
  t_menuPhoneBook: 'Menu - Phone Book',
  t_menuCalendar: 'Menu - Calendar',
  t_menuCrm: 'Menu - CRM',
  t_menuDashboard: 'Menu - Dashboard',
  t_menuEvaluatedRecords: 'Menu - Evaluated Records',
  t_menuSystemManagement: 'Menu - System Management',
  t_menuOperationManagement: 'Menu - Operation Management',
  t_menuIntegrations: 'Menu - Integrations',
  t_menuSettings: 'Menu - Settings',
  t_menuWallboard: 'Menu - Wallboard',
  t_menuDashboard: 'Menu - Dashboard',
  t_menuQueueGroup: 'Menu - Queue Group',
  t_menuvIVR: 'Menu - vIVR',
  t_menuScheduler: 'Menu - Scheduler',
  t_menuExternalChat: 'Menu - External Chat',
  t_menuSurvey: 'Menu - Survey',
  t_menuEMail: 'Menu - E-Mail',
  t_menuAlerts: 'Menu - Alerts',
  t_menuQuality: 'Menu - Quality',
  t_otherAgentList: 'Other - Agent List',
  t_othertAutoCustomerCard: 'Other - Auto Customer Card',
  t_otherDownloadCallRecordings: 'Other - Download Call Recordings',
  t_otherOutboundCallApproval: 'Other - Outbound Call Approval',
  t_otherCallHistoryDownloadRecording: 'Other - Call History Download Recording',
  t_otherCallHistoryListenRecording: 'Other - Call History - Listen Recording',
  t_otherDialerReopenTotalAttempts: 'Other - Dialer Reopen Total Attempts',
  t_otherDialerReopenDailyAttempts: 'Other - Dialer Reopen Daily Attempts',
  t_otherDialerClearCallSchedules: 'Other - Dialer Clear Call Schedules',
  t_otherShowAllFinishCodes: 'Other - Show All Finish Codes',
  t_otherUpdateLastFinishCode: 'Other - Update Last Finish Code',
  t_otherShowIVRInputs: 'Other - Show IVR Inputs',
  t_otherHidePhoneNumbers: 'Other - Hide Phone Numbers',
  t_ChatSouffle: 'Chat - Souffle',
  t_chatChat: 'Chat - Chat',
  t_chatAnnounce: 'Chat - Announce',
  t_crmFilters: 'CRM - Filters',
  t_crmColumns: 'CRM - Columns',
  t_crmManagement: 'CRM - Management',
  t_crmAddExtraData: 'CRM - Add Extra Data',
  t_crmScript: 'CRM - Script',
  t_crmProducts: 'CRM - Products',
  t_crmPhones: 'CRM - Phones',
  t_crmNotes: 'CRM - Notes',
  t_crmSms: 'CRM - SMS',
  t_crmSmsSending: 'CRM - Send',
  t_crmCrmCall: 'CRM - Call',
  t_crmCrmAddCustomer: 'CRM - Add Customer',
  t_crmDisplayAllRecords: 'CRM - Display All Records',
  t_crmEditCustomer: 'CRM - Edit Customer',
  t_crmCrmlistenRecording: 'CRM - CRM Listen Recording',
  t_crmShowNoteDetails: 'CRM - Show Note Details',
  t_crmExcelCustomer: 'CRM - Excel Customer',
  t_crmExcelCustomerByPhone: 'CRM - Excel Customer By Phone',
  t_crmShowPhoneDetails: 'CRM - Show Phone Details',
  t_awaitingInteractionsCount: "Awaiting Interaction",
  t_answeredCount: "Answered Count",
  t_abandonedCount: "Abandon",
  t_activeInteractionsCount: "Active Interactions",
  t_averageWaitTime: "Average Wait Time",
  t_callBackSuccess: "Callback Success",
  t_firstCallResolutionCount: "First Call Resolution",
  t_serviceLevel: "Service Level",
  t_sRLevel: "Success Rate",
  t_averageConnectedTime: "Avg Connnect Time",
  t_abandonRate: "Abandon Rate",
  t_descawaitingInteractionsCount: "Instant total of calls waiting in selected queues",
  t_descansweredCount: "Total number of answered calls in selected queues",
  t_descabandonedCount: "Total number of abandoned calls in selected queues",
  t_descactiveInteractionsCount: "Total number of instant calls in selected queues",
  t_descaverageWaitTime: "Average queue waiting times of incoming calls in selected queues",
  t_desccallBackSuccess: "Number of returns to abandoned calls",
  t_descfirstCallResolutionCount: "Number of calls that were met only once and were closed with result codes generated in the successful category",
  t_descserviceLevel: "The ratio of the number of answered calls to all answered calls within the service levels specified in the queue settings",
  t_descsRLevel: "Ratio of calls completed with successful result codes to all calls",
  t_descaverageConnectedTime: "Average duration of all negotiated calls",
  t_descabandonRate: "Ratio of incoming and unanswered calls to all calls in selected queues",
  t_transferQueue: "Transfer Queue",
  t_deleteQualityForm: "Delete Queliy Form",
  t_crmlistenRecordingJustOwn: "Recording Just Own",
  t_rLogoutCount: "Logout Count",
  t_rProductivityTime: "Productivity Time",
  t_rBreakCategoryTime: "Break Category Time",
  t_rAvgAlertTime: "Avg Alert Time",
  t_addFile: "Add File",
  t_appointment: "Appointment",
  t_appointments: "Appointments",
  t_second: "Second",
  t_notAnsweringCallDesc2: "You have been in Not Ansering status for 1 minute, please switch to Available status to continue.",
  t_notAnsweringCallDesc: "You did not answer the call, switch to Available status to continue.",
  t_notAnsweringCall: "Not Answering Call",
  t_timeFormat: "Time Format",
  t_callGroup: "Call Group",
  t_pickupGroup: "Pickup Group",
  t_fodlerType: "Folder Type",
  t_conferenceCallAccepted: "Call Accepted",
  t_conferenceCallHangup: "Call Hangup",
  t_conferenceCallDeclined: "Call Declined",
  t_conferenceCalling: "Calling",
  t_agentListDetails: "Agent List Details",
  t_rAgentGroup: "Agent Group",
  t_new: "New",
  t_recordsActivityLog: "Records Activity Log",
  t_isAutoForm: "Auto Form",
  t_timestamp: 'Time Stamp',
  t_verificationCode: "Verification Code",
  t_verificationCodeDesc: "Please enter the 6-digit verification code",
  t_verify: "Verify",
  t_phone: "Telephone",
  t_downloadVoyceVoiceConverter: "Download Dialxl Audio Converter",
  t_position: "Position",
  t_ivrBreakOut: "Ivr Break out",
  t_positionAndIvrBreakOut: "Position and Ivr Break Out",
  t_ivrBreakOutTarget: "Ivr Break Out Target",
  t_displayAllCalendar: "Tüm Tavimleri Görüntüle",
  t_appointmentAssignmentDesc: "New appointments have been assigned to your name. Please check the calendar.",
  t_appointmentAssignment: "Appointments Assigment",
  t_displayCalls: 'Call Tracking',
  t_showUsedAreas: "Show Used Areas",
  t_ftp: "FTP",
  t_voiceExport: "Voice Export",
  t_putFtp: "Put Ftp",
  t_remotePath: "Remote Path",
  t_passive: 'Passive',
  t_passives: 'Passives',
  t_state: 'State',
  t_frequentlyAskedQuestions: 'Frequently Asked Questions',
  t_helpCenter: 'Help Center',
  t_contactUs: 'Contact Us',
  t_isSecure: 'is Secure',
  t_rejectUnauthorized: 'Reject Unauthorized',
  t_customMetaData: 'Custom Meta Data',
  t_metaDataEndpoint: 'Meta Data Endpoint',
  t_metaDataRemotePath: 'Meta Data Remote Path',
  t_putAsFolder: 'Put As Folder',
  t_isDefault: 'Default',
  t_rSLDuration: 'SL Duration',
  t_rDialerConnectedToAgent: "Dialer Connected To Agent",
  t_saveFilters: "Save Filters",
  t_rDialerAvgIVRTime: "Dialer Avg IVR Time",
  t_rDialerTotalIVRTime: "Dialer Total IVR Time",
  t_rDialerAbandon: "Dialer Abandon",
  t_rACWLength: "ACW Length",
  t_rIvrWaitLength: "Ivr Wait Length",
  t_rInboundFailSL180: "Inbound Fail SL 180",
  t_rInboundFailSL120179: "Inbound Fail SL 120-179",
  t_rInboundFailSL60119: "Inbound Fail SL 60-119",
  t_rInboundFailSL3059: "Inbound Fail SL 30-59",
  t_rAvgQueueLength: "Avg Queue Length",
  t_reportGroup: "Report Group",
  t_rDialerAvgCallTime: "Dialer Avg Call Time",
  t_rAvgHandleTime: "Avg Handle Time",
  t_rTotalHoldTime: "Total Hold Time",
  t_rTotalHoldCount: "Total Hold Count",
  t_rTotalStatusTime: "Total Status Time",
  t_rTotalInCallTime: "Total In Call Time",
  t_rAvgDialingTime: "Avg Dialing Time",
  t_rOutboundLocal: "Outbound Local",
  t_rInboundLocal: "Inbound Local",
  t_rTotalLocal: "Total Local",
  t_rTotalAvgCallTime: "Total Avg Call Time",
  t_rTotalCallTime: "Total Call Time",
  t_rTotalSuccessCallCount: "Total Success Call Count",
  t_rTotalCallCount: "Total Call Count",
  t_rDialerTotalCallTime: "Dialer Total Call Time",
  t_event: "Event",
  t_callAnalize: "Call Analize",
  t_displayPassiveRecords: "Display Passive Records",
  t_dontChangeSurvey: "Don't Change Survey",
  t_acw: "ACW",
  t_verify: "Verify",
  t_twoFactorAuthentication: "Two Factor Authentication",
  t_remainingTime: "Remaining Time",
  t_verificationCode: "Verification Code",
  t_sendAsMail: "Send as Email",
  t_sendAsSms: "Send as Sms",
  t_sentToPhoneNumber: "phone number to verify your identity,",
  t_sentToEmailAdress: "email to verify your identity,",
  t_verifyYourIdentity: "enter the verification code sent.",
  t_sec: " sec",
  t_donthaveAccess: "Don't have access to this contact account?",
  t_targetCompletion: "Target Completion",
  t_agentGroup: 'Agent Group',
  t_finishCodeType: 'Finish Code Type',
  t_questionsAndAnswers: 'Questions & Answers',
  t_youCanAddCommaBetweenPhoneToAddMore: 'You can add comma between phone to add more',
  t_currentPasswordDoesNotMatch: 'current password does not match',
  t_closeProduct: "Close Product",
  t_setPassiveWhenNoProduct: "To set records without any products to inactive",
  t_sendHttpRequest: "Send Http Request",
  t_month2: "Month (Non Zero)",
  t_day2: "Day (Non Zero)",
  t_shifts: "Shifts",
  t_shift: "Shift",

}
