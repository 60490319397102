<template>
     <div
      v-if="is_progress"
      style="
        z-index: 999;
        position: absolute;
        height: 100vh;
        width: 100%;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        overflow-y: hidden;
      "
    >
      <b-spinner
        variant="primary"
        style="width: 3rem; height: 3rem"
        label="Loading..."
        
      />
    </div>
</template>

<script>
import { BSpinner } from 'bootstrap-vue'

export default {
  components: {
    BSpinner,
  },
  props: {
    is_progress: {
      required: true,
      type: Boolean,
    },
  },
}
</script>
