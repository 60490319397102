import tr from './tr_huso.js'
import en from './en_huso.js'
const lang = {}

lang.default = "tr";
lang.tr = tr
lang.en = en

// function _l(key){
//     let selectedLang = window.localStorage.getItem('language') || lang.default;
//     let path = key.split(".");
//     return access(lang[selectedLang],key) || path[path.length - 1];
// }

function _l(selectedLang){
  return function(token){
    return lang[selectedLang][token] || token;
  };
}

function selectLang(lang){
    window.localStorage.setItem('language', lang);
    globalThis.v_navbar.lang= _l(lang);
    globalThis.LayoutContentRendererDefault.lang= _l(lang);
    window.location.reload();
}

const access = (object, path) => {
    const pathArray = Array.isArray(path) ? path : path.split(".");
    const lastIndex = pathArray.length - 1;
    return lastIndex > 0
        ? access(object, pathArray.slice(0, lastIndex))[pathArray[lastIndex]]
        : object[pathArray[0]];
}

export {
  lang, _l, selectLang, access
}
