import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'

import ECharts from 'vue-echarts/components/ECharts'
Vue.component('e-charts', ECharts);
import 'echarts/lib/chart/bar'
import 'echarts/lib/chart/line'

import theme from '@core/components/charts/echart/theme.json'
ECharts.registerTheme('theme-color', theme)

Vue.component(FeatherIcon.name, FeatherIcon)
